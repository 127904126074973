import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout/layout'

const VinoPage = () => (
  <Layout>
    <h1>Dulce Corazón Blanco</h1>
    <h2>D.O. Manchuela</h2>
    <p>Vino semi-dulce blanco</p>
    <p>Vino elaborado con la variedad Moscatel. En nariz resulta muy atractivo, limpio y aromático. En boca se muestra elegante, fresco y sorprendente, con recuerdos a mandarina y a pétalos de rosa. Dulzor equilibrado y duradero.</p>
    <Link to="/vinos">Back</Link>
  </Layout>
)

export default VinoPage
